.dg-quantity-wrapper {
	width: 350px;
	height: 55px;
	margin: 5px auto 0;

	input {
		@apply relative z-0 cursor-pointer bg-white font-body font-semibold;
		height: 55px;
		border: 1px solid $dg-gray-light;
		color: $dg-blue;
		font-size: 16px;

		&::placeholder {
			color: $dg-gray-light;
		}

		&.active {
			border-color: $dg-red;
		}

		&:disabled {
			@apply cursor-default opacity-100;
			background: #f2f2f2;
		}

		&.error {
			background: #d8b5b9;
		}
	}
}

.dg-quantity-dropdown {
	@apply absolute top-0 bg-white;
	left: 400px;
	border-top-right-radius: 10px;
	border-bottom-right-radius: 10px;
	height: 432px;
	width: 280px;
	padding: 15px;
	z-index: -1;
	margin-left: -290px;
	transition: margin-left 0.25s ease-in-out, opacity 0.1s ease-in-out;

	&.open {
		margin-left: -10px;
	}

	&.with-redemption-miles-and-promo-code {
		height: 482px;
	}

	.dg-quantity-label {
		@apply select-none font-body font-semibold;
		margin-right: $spacing;
		align-self: center;
		font-size: 15px;
		color: $dg-blue;
	}

	.dg-age-label {
		@apply select-none font-body font-semibold;
		margin-top: 4px;
		font-size: 12px;
		color: $dg-blue;
	}
}

.dg-pax-warning {
	color: $dg-red;
	margin-top: 10px;
	font-size: 14px;
}

/* MEDIA QUERIES */

@media #{$large} {
	.dg-quantity-wrapper {
		width: 310px;
	}

	.dg-quantity-dropdown {
		left: 340px;
	}
}

@media #{$medium} {
	.dg-quantity-wrapper {
		width: 260px;
	}

	.dg-quantity-dropdown {
		left: 280px;
		max-width: 270px;

		.dg-quantity-label {
			font-size: 13px;
		}
	}
}

@media #{$small} {
	.dg-quantity-wrapper {
		width: 260px;
	}

	.dg-quantity-dropdown {
		left: 280px;
		max-width: 270px;

		.dg-quantity-label {
			font-size: 13px;
		}
	}

	.dg-quantity-header {
		@apply flex items-center justify-end;
		height: 30px;

		span {
			@apply cursor-pointer font-semibold;
			font-size: 30px;
			color: $dg-blue;
		}
	}
}

@media #{$xsmall} {
	.dg-quantity-wrapper {
		width: calc(50% - 20px);
		float: left;
		margin-left: 15px;

		input {
			height: 45px;
			color: $dg-blue;
		}
	}

	.dg-quantity-dropdown {
		@apply inset-x-0 top-0 ml-0 h-auto w-auto;
		display: none;
		bottom: -50px;
		z-index: 10;

		&.open {
			@apply block;
			border-radius: 10px;
		}
	}

	.dg-quantity-header {
		@apply flex items-center justify-end;
		height: 30px;

		span {
			@apply cursor-pointer font-semibold;
			font-size: 30px;
			color: $dg-blue;
		}
	}
}
